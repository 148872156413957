.sale-watch {
  padding: 1.5em;
  margin: 0;
  width: 100%;
  height: 100vh;

  $panel-back: #132C40;
  $panel-text: #fff;

  .panel {
    display: flex;
    flex-direction: column;
  }

  .panel-custom {
    border-color: $panel-back;
    min-height: 0;

    & > .panel-heading {
      color: $panel-text;
      background-color: $panel-back;
      border-color: $panel-back;
    }

    & > .panel-body.scroll {
      overflow-y: auto;
      max-height: calc(100% + 38px);

      table:only-child {
        margin: -15px;
        width: calc(100% + 30px);
        max-width: calc(100% + 30px);
      }
    }
  }

  .auction-title {
    font-size: 2em;
  }
  .blink {
    -webkit-animation-name: blinker;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    
    -moz-animation-name: blinker;
    -moz-animation-duration: 1s;
    -moz-animation-timing-function: linear;
    -moz-animation-iteration-count: infinite;
    
    animation-name: blinker;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  .ending-blinker {
    -webkit-animation-name: ending-blinker;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    
    -moz-animation-name: ending-blinker;
    -moz-animation-duration: 1s;
    -moz-animation-timing-function: linear;
    -moz-animation-iteration-count: infinite;
    
    animation-name: ending-blinker;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  @-moz-keyframes blinker {  
      0% { opacity: 1.0; }
      50% { opacity: 0.0; }
      100% { opacity: 1.0; }
  }

  @-webkit-keyframes blinker {  
      0% { opacity: 1.0; }
      50% { opacity: 0.0; }
      100% { opacity: 1.0; }
  }

  @-moz-keyframes ending-blinker {  
    0% { background-color: initial; }
    50% { background-color: red; }
    100% { background-color: initial; }
  }

  @-webkit-keyframes ending-blinker {  
    0% { background-color: initial; }
    50% { background-color: red; }
    100% { background-color: initial; }
  }

  @keyframes blinker {  
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
  }

  @keyframes ending-blinker {  
    0% { background-color: initial; }
    50% { background-color: red; }
    100% { background-color: initial; }
  }
}