.navbar-brand img{
  height:100%;
}

body {
  font-family: 'Roboto', sans-serif;
}

#errPage h1{
  font-size: 100px;
}

#loginPage h1 {
  font-size: 50px;
}

#loginPage form {
  width: 100%;
  max-width: 300px;
  margin: 40px auto 0 auto;
}

#loginPage form .form-group {
  margin-bottom:5px;
}

label {
  float:left;
  margin-bottom: 0;
}

.navbar-header {
  width: 100%;
}

.navbar-header .logout {
  color: #333;
  margin: 8px 0;
}

.mce-tinymce {
  margin-top: 20px!important;
}

.date {
  width:100%;
}

.hasError {
  border-color: red;
}

.table-striped tbody tr{
  border-bottom: 1px solid #ECECEC;
  background-color: #FFF;
}

.btn-primary-outline {
  color: #0275d8;
  background-color: transparent;
  background-image: none;
  border-color: #0275d8;
}

.btn-success-outline {
  color: #449d44!important;
  background-color: transparent;
  background-image: none;
  border-color: #449d44;
}

.intl-tel-input{
  width:100%;
}

@-webkit-keyframes slide-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  30% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
@-moz-keyframes slide-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  30% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
@keyframes slide-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  30% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.upload {
  min-height: auto;
  border: 3px dashed #CCC !important;
  border-radius: 15px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  margin-top: 20px;
  padding: 20px;
}
.dropzone
{
  min-height: auto;
  border: 1px solid #e7eaec !important;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.dropzone .dropzone-previews { position: relative; }

.dropzone .dz-sort-help { display: block; margin: 0 0 10px 0; }
.dropzone .dz-progress-bar { margin-bottom: 15px; }
.dropzone .dz-progress-bar .progress { margin-bottom: 2px; }
.dropzone .dz-message {
    cursor: pointer;
    margin: 0;
    padding: 2em;
    border: 1px dashed #e7eaec;
    border-radius: 10px;
}
.dropzone.dz-started .dz-message { display: block; }

.dropzone.dz-drag-hover .dz-message, .dropzone .dz-message:hover {
    background-color: #e7eaec;
    opacity: 1;
}
.dropzone .dz-preview .dz-image {
    width: 118px;
}
.dropzone .dz-preview {
    margin: 0 20px 20px 0;
    background-color: transparent;

}
.dropzone .dz-preview[data-file_name="contract"],
.dropzone .dz-preview[data-file_name="jof"] {
    border: 1px solid #e7eaec;
}
.dropzone .dz-preview.dz-image-preview { background-color: transparent; }

.dropzone .dz-preview.ui-sortable-placeholder
{
    background: #e7eaec;
    border: 1px dashed #e7eaec;
    border-radius: 10px;
}

.dropzone .dz-preview:hover .dz-image img { -webkit-transform: none; -moz-transform: none; -ms-transform: none; -o-transform: none; transform: none; -webkit-filter: none; filter: none; }

.dropzone .dz-preview.dz-error:hover .dz-error-message
{
    opacity: 0;
    pointer-events: none;
}

.dropzone .dz-preview.dz-waiting .dz-waiting-mark
{
    opacity: 1;
    -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
    -moz-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
    -ms-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
    -o-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
    animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone .dz-preview.dz-complete .dz-waiting-mark
{
    opacity: 0;
    -webkit-animation: slide-out 3s cubic-bezier(0.77, 0, 0.175, 1);
    -moz-animation: slide-out 3s cubic-bezier(0.77, 0, 0.175, 1);
    -ms-animation: slide-out 3s cubic-bezier(0.77, 0, 0.175, 1);
    -o-animation: slide-out 3s cubic-bezier(0.77, 0, 0.175, 1);
    animation: slide-out 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone .dz-preview.dz-error.dz-preview-hover .dz-error-message
{
    opacity: 1;
    pointer-events: auto;
}

.dropzone .dz-preview a { cursor: default; }
.dropzone .dz-preview a[href] { cursor: pointer; }
.dropzone .dz-preview .dz-progress
{
    border: 0;
    background: rgba(255, 255, 255, 0.5);
}
.dropzone .dz-preview .dz-progress .dz-upload { background: #fff; }

.dropzone .dz-preview .dz-image
{
    background-color: #fff;
    border-radius: 10px;
}
.dropzone .dz-preview .dz-image img { width: 100%; }
.dropzone .dz-preview .dz-image i
{
    position: absolute;
    margin: -25px 0 0 -25px;
    top: 50%;
    left: 50%;
    width: 51px;
    height: 51px;
    font-size: 51px;
    line-height: 51px;
    text-align: center;
    color: #696969;
}
.dropzone .dz-preview .dz-image i.fa-file-pdf-o { color: #B10302; }
.dropzone .dz-preview .dz-image i.fa-file-word-o { color: #D24726; }
.dropzone .dz-preview .dz-image i.fa-file-excel-o { color: #207245; }
.dropzone .dz-preview .dz-image i.fa-file-powerpoint-o { color: #D24726; }
.dropzone .dz-preview .dz-image i.fa-file-archive-o { color: #696969; }
.dropzone .dz-preview .dz-image .media-more i { color: #fff; }

.dropzone .dz-preview .dz-success-mark,
.dropzone .dz-preview .dz-error-mark,
.dropzone .dz-preview .dz-waiting-mark
{
    pointer-events: none;
    display: block;
    position: absolute;
    margin: -25px 0 0 -25px;
    top: 50%;
    left: 50%;
    width: 51px;
    height: 51px;
    opacity: 0;
    z-index: 500;


}
.dropzone .dz-preview .dz-success-mark i,
.dropzone .dz-preview .dz-error-mark i,
.dropzone .dz-preview .dz-waiting-mark i
{
    margin: -25px 0 0 -25px;
    color: #fff;
    text-shadow: 0 0 3px rgba(0,0,0,.25);
}

.dropzone .dz-preview .dz-waiting-mark i
{
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    -ms-animation: spin 2s linear infinite;
    -o-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    transform-origin: 25px 25px;
}

.dropzone .dz-preview .dz-details
{
    position: relative;
    padding: 8px 0 0 0;
    line-height: 1.2;
    opacity: 1;
}
.dropzone .dz-preview .dz-details .dz-filename,
.dropzone .dz-preview .dz-details .dz-size {
    display: block;
    margin: 0;
    font-size: inherit;

}
.dropzone .dz-preview .dz-details .dz-filename span,
.dropzone .dz-preview .dz-details .dz-size span {
    background: transparent;
    border-radius: 0;
}
.dropzone .dz-preview .dz-details .dz-filename:hover span { border: 0; }
.dropzone .dz-preview .dz-details .dz-size strong { font-weight: normal; }
.dropzone .dz-preview .dz-details span { padding: 0 !important; }

.dropzone .dz-preview .dz-options
{
    display: none;
    position: absolute;
    padding: 5px;
    top: 5px;
    right: 5px;
    color: #000;
    background: #fff;
    border-radius: 50px;
    box-shadow: 0 0 3px rgba(0, 0, 0, .25);
    z-index: 100;
}

.dropzone .dz-preview .dz-icons
{
    display: none;
    position: absolute;
    top: 5px;
    left: 5px;
    color: #000;
    z-index: 100;
}
.dropzone .dz-preview .dz-icons i {
    padding: 5px;
    background: #fff;
    border-radius: 50px;
    box-shadow: 0 0 3px rgba(0, 0, 0, .25);
}

.dropzone .dz-preview.dz-success .dz-options,
.dropzone .dz-preview.dz-success .dz-icons { display: block; }

.dropzone .dz-preview.dropdown.open a.dz-image,
.dropzone .dz-preview.dropdown.open a.dz-filename { pointer-events: none; }

.dropzone .dz-preview .dropdown-toggle { cursor: pointer; }
.dropzone .dz-preview .dropdown-menu {
    top: 35px;
    left: 6px;
    right: 6px;
    min-width: auto;
}
.dropzone .dz-preview .dropdown-menu li { padding: 0 4px; }
.dropzone .dz-preview .dropdown-menu li:first-child { padding-top: 4px; }
.dropzone .dz-preview .dropdown-menu li:last-child { padding-bottom: 4px; }
.dropzone .dz-preview .dropdown-menu a { cursor: pointer; margin: 0; padding: 0 5px; line-height: 23px; }
.dropzone .dz-preview .dropdown-menu i { margin-right: 5px; }
.dropzone .dz-preview .dropdown-menu .divider { margin: 0 }
.dropzone .dz-preview .dropdown-menu:before, .dropzone .dz-preview .dropdown-menu:after
{
    display: inline-block;
    position: absolute;
    content: '';
}

.dropzone .dz-preview .dropdown-menu:before
{
    top: -7px;
    right: 3px;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
}
.dropzone .dz-preview .dropdown-menu:after
{
    top: -6px;
    right: 4px;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    border-left: 6px solid transparent;
    content: '';
}
.dropzone.dz-single .dropzone-previews { text-align: center; }
.dropzone.dz-single .dz-preview { margin: 0 0 20px 0; }

.dz-preview.ui-sortable-helper .dz-image {
    background-color: #fff;
    border-radius: 10px;
    width: 118px;
    height: 120px;
    position: relative;
    display: block;
    overflow: hidden;
    z-index: 1049;
}
.dz-preview.ui-sortable-helper .dz-image img { width: 100%; }
.dz-preview.ui-sortable-helper .dz-image i {
    position: absolute;
    margin: -25px 0 0 -25px;
    top: 50%;
    left: 50%;
    width: 51px;
    height: 51px;
    font-size: 51px;
    line-height: 51px;
    text-align: center;
    color: #696969;
}
.dz-preview.ui-sortable-helper .dz-image i.fa-file-pdf-o { color: #B10302; }
.dz-preview.ui-sortable-helper .dz-image i.fa-file-word-o { color: #D24726; }
.dz-preview.ui-sortable-helper .dz-image i.fa-file-excel-o { color: #207245; }
.dz-preview.ui-sortable-helper .dz-image i.fa-file-powerpoint-o { color: #D24726; }
.dz-preview.ui-sortable-helper .dz-image i.fa-file-archive-o { color: #696969; }
.dz-preview.ui-sortable-helper .dz-options {
    position: absolute;
    padding: 5px;
    top: 5px;
    right: 5px;
    color: #000;
    background: #fff;
    border-radius: 50px;
    box-shadow: 0 0 3px rgba(0, 0, 0, .25);
    z-index: 1050;
}
.dz-preview.ui-sortable-helper .dz-icons {
    position: absolute;
    top: 5px;
    left: 5px;
    color: #000;
    z-index: 1050;
}
.dz-preview.ui-sortable-helper .dz-icons i {
    padding: 5px;
    background: #fff;
    border-radius: 50px;
    box-shadow: 0 0 3px rgba(0, 0, 0, .25);
}
.dz-preview.ui-sortable-helper .dz-details {
    z-index: 1049;
    position: relative;
    padding: 8px 0 0 0;
    line-height: 1.2;
    opacity: 1;
    top: 0;
    left: 0;
    font-size: 13px;
    min-width: 100%;
    max-width: 100%;
    text-align: center;
    color: rgba(0, 0, 0, 0.9);
}

.dashboard-btn {
  width: 100px;
  height: 100px;
  margin: 5px;
  padding-top: 12px;
}
.dashboard-btn i{
  font-size: 40px;
}
.dashboard-btn span{
  display: inline-block;
  padding-top: 10px;
  font-size: 20px;
  line-height: 24px;
}

::-webkit-scrollbar-track{background-color:rgba(0,0,0,0)}::-webkit-scrollbar{width:10px;background-color:rgba(0,0,0,0)}::-webkit-scrollbar-thumb{border:1px solid rgba(0,0,0,0);border-radius:10px;background-color:#939393}::-webkit-scrollbar-corner{background-color:rgba(0,0,0,0)}body,html{scrollbar-arrow-color:#939393;scrollbar-track-color:rgba(0,0,0,0);scrollbar-face-color:#939393;scrollbar-highlight-color:rgba(0,0,0,0);scrollbar-3dlight-color:rgba(0,0,0,0);scrollbar-shadow-color:rgba(0,0,0,0);scrollbar-darkshadow-color:rgba(0,0,0,0)}


.nav-tabs {
  border-bottom: 2px solid #DDD;
  margin-bottom: 10px;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
  border-width: 0;
}

.nav-tabs > li > a {
  border: none;
  color: #666;
}

.nav-tabs > li.active > a, .nav-tabs > li > a:hover {
  border: none;
  color: #4285F4 !important;
  background: transparent;
}

.nav-tabs > li > a::after {
  content: "";
  background: #4285F4;
  height: 2px;
  position: absolute;
  width: 100%;
  left: 0px;
  bottom: -1px;
  transition: all 250ms ease 0s;
  transform: scale(0);
}

.nav-tabs > li.active > a::after, .nav-tabs > li:hover > a::after {
  transform: scale(1);
}

.tab-nav > li > a::after {
  background: #21527d none repeat scroll 0% 0%;
  color: #fff;
}

.tab-pane {
  padding: 15px 0;
}

.tab-content {
  padding: 20px;
}

#titleBar i {
  font-size:25px;
  margin-right: 7px;
}
#titleBar strong {
  font-size:25px;
}
#titleBar span {
  font-size:12px;
  margin-left:5px;
}

.sidebar-nav li .fa{
  margin-right: 5px;
}

.flot-chart {
  display: block;
  height: 200px;
}
.flot-chart-content {
  width: 100%;
  height: 100%;
}

.dashboard-metric span{
  color: #848484;
  font-size: 11px;
}
.dashboard-metric strong{
  display: inline-block;
  width: 100%;
  font-size: 28px;
  margin-top: -4px;
}

.jsonpanel{-moz-box-sizing:border-box;-webkit-box-sizing:border-box;box-sizing:border-box;white-space:nowrap;font-family:monospace;line-height:1.4}.jsonpanel *{margin:0;padding:0}.jsonpanel .panel{display:inline-block;vertical-align:top}.jsonpanel .list{list-style-type:none;padding:.5em 0}.jsonpanel ul:before{content:'{'}.jsonpanel ul:after{content:'}'}.jsonpanel ol:before{content:'['}.jsonpanel ol:after{content:']'}.jsonpanel .key{margin-left:1em;font-weight:700}.jsonpanel .pair.simple{max-width:600px;text-indent:-30px;white-space:normal;border:none;margin:0;padding:0 0 0 30px}.jsonpanel .pair .val:after{content:","}.jsonpanel .pair:last-child .val:after{display:none}.jsonpanel .val-inner{display:inline-block;max-width:28em;overflow:hidden;text-overflow:ellipsis;vertical-align:top}.jsonpanel .expander{display:block;text-decoration:none;color:#000;background-color:rgba(211,223,240,0);-moz-transition:background-color .3s ease .5;-o-transition:background-color .3s ease .5;-webkit-transition:background-color .3s ease;-webkit-transition-delay:.5;transition:background-color .3s ease .5}.jsonpanel .expander:hover{background-color:#d3dff0}.jsonpanel .expander .key:before{content:"+";margin-right:.5em}.jsonpanel .expanded{position:relative}.jsonpanel .expanded>.expander .key:before{content:"-"}.jsonpanel .expanded>.expander .val{opacity:0}.jsonpanel .expanded>.panel{margin-left:2em}.jsonpanel .boolean{color:red}.jsonpanel .string{color:green}.jsonpanel .number{color:#00f}.jsonpanel .array .val-inner,.jsonpanel .object .val-inner{color:#a5a5a5}

.striped>.row {
  padding-top: 4px;
  padding-bottom: 4px;
  border-bottom: 1px solid #ECECEC
}
.striped>.row:nth-child(odd){
  /*background-color:#ECECEC;*/
}

.accordion-toggle {
  position: relative;
}
.accordion-toggle::before,
.accordion-toggle::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: -9px;
  width: 10px;
  height: 2px;
  margin-top: -2px;
  background-color: #337ab7;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}
.accordion-toggle::before {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  opacity: 0;
}
.accordion-toggle.collapsed::before {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  opacity: 1;
}
.accordion-toggle.collapsed::after {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.pop-page{
    width: 100%;
    margin: 0 auto;
    max-width: 1000px;
    padding: 25px;
    box-shadow: 0px 1px 10px #CCC;
}
.overlay {
  display: none;
  z-index: 999;
  position: absolute;
  top: -10px;
  right: -20px;
  bottom: -20px;
  left: -20px;
  opacity: 0;
  background-color: #333;
  transition: all .5s linear;
}
.overlay.visible {
  opacity: .5;
}
.overlay div{
  position: fixed;
  right: 20px;
  bottom: 20px;
}
.overlay i{
  font-size: 35px;
  color: #000;
}

.invoice-bar{
  background: #EAEAEA;
  padding:5px;
  border-top:1px solid #CCC;
  margin-top:5px;
  margin-bottom: 5px;
  margin-left: -5px;
  margin-right: -5px;
}

.clickable{
    cursor: pointer;
}

a.btn.disabled.pointer-events{
  pointer-events: all;
}

#sndtinygallery,
#sndtinylinkgallery {
  width: 85% !important;
  height: 90% !important;
  left: 7.5% !important;
  top: 5% !important;
}

#sndtinygallery .mce-reset,
#sndtinylinkgallery .mce-reset {
  height: 100% !important;
}

#sndtinygallery .mce-reset #sndtinygallery-body,
#sndtinylinkgallery .mce-reset #sndtinylinkgallery-body {
  width: 100% !important;
  height: calc(100% - 90px) !important;
}

#sndtinygallery .mce-reset .mce-container.mce-foot,
#sndtinylinkgallery .mce-reset .mce-container.mce-foot {
  width: 100%;
}

#gallery-images.dz-max-files-reached > .dz-message {
  display: none;
}
#gallery-images .dropzone-previews .dz-preview {
  padding: 5px;
  width: auto;
}
#gallery-images .dropzone-previews .dz-preview.dz-selected {
  border: 2px solid black;
  border-radius: 10px;
  background-color: #6495ed;
}

.gallery-sel-image-preview {
  height: calc(((25vw - 60px) / 4) * 3);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid black;
}


.truncate {
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative; 
  /* use this value to count block height */
  line-height: 1.2em;
  /* max-height = line-height (1.2) * lines max number (3) */
  max-height: 3.6em; 
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;  
  /* place for '...' */
  margin-right: -1em;
  padding-right: 1em;
}

.truncate:before {
   /* points in the end */
   content: '...';
   /* absolute position */
   position: absolute;
   /* set position to right bottom corner of block */
   right: 0;
   bottom: 0;
}

.truncate:after {
  /* points in the end */
  content: '';
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of text */
  right: 0;
  /* set width and height */
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  /* bg color = bg color under block */
  background: white;
} 

.invoice-description {
  width: 100%;
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative; 
  /* use this value to count block height */
  line-height: 1.2em;
  /* max-height = line-height (1.2) * lines max number (3) */
  max-height: 8.0em; 
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;  
  /* place for '...' */
  margin-right: -1em;
  padding-right: 1em;
}
.invoice-description:before{
  /* points in the end */
  content: '';
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of block */
  right: 0;
  bottom: 0;
}

.invoice-description:after{
  /* points in the end */
  content: '';
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of text */
  right: 0;
  /* set width and height */
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  /* bg color = bg color under block */
}