.modal-dialog {
  &.modal-fill {
    width: 90%;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    border-radius: 6px;

    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;

      &::before, &::after {
        content: none;
      }

      & > button:first-child {
        order: 2;
      }
    }

    .modal-body {
      flex: 1 1 auto;
      max-height: calc(100vh - 210px);
      overflow-y: auto;
      padding-bottom: 20px;

      .modal-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    .modal-footer {
      display: flex;
      justify-content: center;
      align-items: center;

      .left { margin-right: auto; }
      .right { margin-left: auto; }
      .btn { margin: 0 5px; }

      &::before, &::after {
        content: none;
      }

      & > *:first-child:not(.left):not(.right) {
        margin-left: auto;
      }
    }
  }
}