select.form-control:not(.defined-width) + .select2-container {
  width: 100% !important;
}
.select2-container--default .select2-selection--single {
  height: 34px;
  border: 1px solid #ccc;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;

  .select2-selection__rendered {
    padding: 6px 20px 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
  }
  .select2-selection__arrow {
    height: 32px;
  }
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  min-height: 34px;
  border: 1px solid #ccc;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.select2-container--default .select2-selection--multiple {
  min-height: 34px;
  border: 1px solid #ccc;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

// select.form-control.select2 + .select2-container {
//   width: 100% !important;
// }

// select.form-control.select2.hasError + .select2-container {
//   .select2-selection--single {
//     border-color: red;
//   }
// }

select.form-control {
  &:not(.defined-width) {
    &.select2, &.select2-modal {
      & + .select2-container {
        width: 100% !important;
      }
    }
  }

  &.select2, &.select2-modal {
    &.hasError + .select2-container {
      .select2-selection--single {
        border-color: red;
      }
    }
  }
}

.select2-results {
  .option-group { font-weight: bold; }
  .option-child { padding-left: 15px; }

  .select2-create {
    padding: 6px;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    display: block;
    font-style: italic;
  }
}