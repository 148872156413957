#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  /*padding-left: 250px;*/
}

#sidebar-wrapper {
  z-index: 1001;
  position: fixed;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  background: #132C40;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 15px;
  padding-top: 0px;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}

/* Sidebar Styles */

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nav li {
  text-indent: 20px;
  line-height: 40px;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #999999;
}

.sidebar-nav li a:hover {
  text-decoration: none;
  color: #fff;
  background: rgba(255,255,255,0.2);
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
  height: 140px;
  font-size: 18px;
  line-height: 60px;
  background: #0F2333;
  display: flex;
  align-items: center;
  justify-content: center;

}
.sidebar-nav > .sidebar-brand img{
max-height: 130px;
}

.sidebar-nav > .sidebar-brand a {
  color: #999999;
}

.sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: none;
}

@media(min-width:768px) {
  #wrapper {
      padding-left: 250px;
  }

  #wrapper.toggled {
      padding-left: 0;
  }

  #sidebar-wrapper {
      width: 250px;
  }

  #wrapper.toggled #sidebar-wrapper {
      width: 0;
  }

  #page-content-wrapper {
      padding: 20px;
      position: relative;
  }

  #wrapper.toggled #page-content-wrapper {
      position: relative;
      margin-right: 0;
  }
}

.modal-content {
border-radius: 0px;
background-clip: border-box;;
border: 1px solid rgba(0,0,0,.2);
}
